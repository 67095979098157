
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatableLeft.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import EntityModal from '@/layout/header/partials/association/EntityListDrawer.vue';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'new-entity-course',
  components: {
    EntityModal,
    Datatable,
  },
  data() {
    return {
      actionApprove: false,
      actionEdit: false,
      actionReject: false,
      entityInfos: [],
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '170px',
        },

        {
          name: 'Name',
          key: 'name',
          sortable: true,
        },
        {
          name: 'Short Name',
          key: 'short_name',
          sortable: true,
        },
        {
          name: 'Address',
          key: 'address',
          sortable: true,
        },
        {
          name: 'Phone',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Website',
          key: 'website',
          sortable: true,
        },
        {
          name: 'Requested by',
          key: 'requested_by',
          sortable: true,
        },
        {
          name: 'Request Date Time',
          key: 'requested_date_time',
          sortable: true,
          width: '150px',
        },
      ],
      loading: true,
      tableData: [],
      componentKey: 0,
    };
  },
  async created() {
    await this.actioncheck();
    await this.getPendingEntityInfos();
    Object.assign(this.tableData, this.entityInfos);
    this.emitter.on('infos-updated', async () => {
      await this.getPendingEntityInfos();
      this.tableData = this.entityInfos;
    });
  },

  methods: {
    async getPendingEntityInfos() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/pending_list?entity_id=' + entity_id)
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    edit(data) {
      this.emitter.emit('entity-edit-modal-data', data);
    },

    Approve(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to approve it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approved!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('entity/approve/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('infos-updated', true);
              Swal.fire('Approved!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },

    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == 'Entity') {
            console.log(menu[i].action);
            let actions = menu[i].action;
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Approve') {
                this.actionApprove = true;
              }
              if (actions[j].action_name === 'Edit') {
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Reject') {
                this.actionReject = true;
              }
            }
          }
        }
      }
    },
    Reject(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to reject it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Rejected!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('entity/status_change/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('infos-updated', true);
              Swal.fire('Rejected!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {},
});
